/* Progress bar styles */


.progress-bar_ {
    height: 100%;
    background-color: #007bff;
    transition: width 0.3s ease;
}

/* Step content styling */
.step-content {
    min-height: 300px;
    padding: 20px;
    border: 1px solid #dee2e6;
    border-radius: 8px;
}

/* Navigation buttons container */
.navigation-buttons {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
}

.w-h-1 {
    width: 100px;
    height: 100px;

}

.cursor-pointer {
    cursor: pointer;
}
/* const SelectedOptions: React.FC<SelectedOptionsProps> = ({ objs, t }) => {
    return (
        <div className="selected-options-container">
            <Card inner={
                <div>
                    <h2 className="selected-options-title">{t('selectedOptions')}</h2>
                    <ul className="selected-options-list">
                        {objs.map((obj, index) => (
                            <li key={index} className="selected-option-item">
                                <span className="option-key">{Object.keys(obj)[0]}:</span>
                                <span className="option-value">{String(Object.values(obj)[0])}</span>
                            </li>
                        ))}
                    </ul>
                </div>
            } />
        </div>
    );
}; */

.selected-options-container {
    /* margin-top: 20px; */
}

.selected-options-title {
    margin-bottom: 10px;
}

.selected-options-list {
    list-style: none;
    padding: 0;
}

.selected-option-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
}

.option-key {
    font-weight: bold;
}

.option-value {
    color: #6c757d;
}

.input_bg {
    background-color: #fff;
    /* box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.25); */
    border-radius: 16px;
    border: 1px solid #F6F3FC;
}
.input_bg input:focus,.input_bg input {
    outline: none;
    box-shadow: none;
    border-color: #F6F3FC;
margin-top: 0 !important;
border: 0 !important;}
