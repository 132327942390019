.devider::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 240px;
  width: calc(50vw - 352px);
  height: 1px;
  background-color: #CFCFCF;
}

.devider::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 240px;
  width: calc(50vw - 352px);
  height: 1px;
  background-color: #CFCFCF;
}

.devider {
  position: relative;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  color: #CFCFCF;
}